import React, { Component, useContext } from 'react'
import PropTypes from 'prop-types'
import ErrorMessage from '../common/ErrorMessage'
import { AppContext } from '../contexts/AppContext'
import { clearAllCaches } from '../contexts/app/dispatchers'

interface Props {
  children: JSX.Element;
}

interface State {
  error?: Error;
}

const CacheClearMessage = (props: { resetErrorBoundary: () => void}) => {
	const { appDispatch } = useContext(AppContext)
	return <ErrorMessage
		onlyShowError={true}
		error={{ message: `
			Unfortunately the amount of data stored by the diary is more than your browser currently allows.
			Please click here to clear your local data (excluding unsaved entry data) and refresh the page.`
		}}
		action={() => {
			props.resetErrorBoundary()
			clearAllCaches(appDispatch)
		}}
	/>
}

export default class ErrorBoundary extends Component<Props, State> {
	public state: State = {	}

	static getDerivedStateFromError(error: Error): State {
		// Update state so the next render will show the fallback UI.
		return { error }
	}

	componentDidCatch(): void {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
	}

	render(): JSX.Element {
		if (String(this.state.error).match(/Setting the value of .* exceeded the quota/)) {
			return <CacheClearMessage resetErrorBoundary={() => this.setState({ error: undefined })} />
		}
		if (this.state.error) {
			return <ErrorMessage error={this.state.error} />
		}

		return this.props.children
	}

	static get propTypes(): unknown {
		return {
			children: PropTypes.node
		}
	}
}
