import React, { useContext } from 'react'
import Sync from './Sync'
import Prepare from './Prepare'
import { faAward, faBolt, faCalendarDay, faCalendarWeek, faKey, faListAlt, faListCheck, faMapMarkerAlt, faMicrophoneLines, faMoneyBill,
	faQuoteLeft, faRecycle, faSignOutAlt, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp, library } from '@fortawesome/fontawesome-svg-core'
import { AppContext } from '../contexts/AppContext'
import { clearAllCaches } from '../contexts/app/dispatchers'
library.add(faSignOutAlt, faQuoteLeft, faMoneyBill, faMapMarkerAlt, faMicrophoneLines,
	faStar, faBolt, faAward, faCalendarDay, faCalendarWeek, faListAlt, faRecycle, faListCheck, faKey)

const UserPropertyToggle = (props: {field: string, title: string, description?: string}): JSX.Element => {
	const { appState, appDispatch } = useContext(AppContext)
	const savedValue = !!appState.user?.row?.[props.field]
	const updatedValue = appState.user?.updated?.[props.field]
	return <div className='thumbnail thumbnail-static'>
		{ props.description && <div className='tip'>{props.description}</div>}
		<input type='checkbox' id={props.field}
			disabled={updatedValue !== undefined}
			checked={updatedValue !== undefined ? !!updatedValue : savedValue}
			onChange={() => appDispatch({
				type: 'UPDATE_USER_FIELD',
				field: 'is_power_user',
				value: !appState.user?.row?.[props.field] })} />
		<label htmlFor={props.field}>{props.title}</label>
	</div>
}

const UserFieldToggle = (props: {field: string}): JSX.Element => {
	const { appState, appDispatch, hasLoginScope } = useContext(AppContext)
	const setting = 'write_' + props.field
	const savedValue = !!appState.user?.row?.[setting]
	const updatedValue = appState.user?.updated?.[setting]
	const field = WRITE_FIELDS[props.field]
	const title = field.title || props.field.substr(0, 1).toUpperCase() + props.field.substr(1)
	if (field.adminOnly && !hasLoginScope('admin')) {
		return <></>
	}
	return <p>
		<input type='checkbox' id={setting}
			disabled={updatedValue !== undefined}
			checked={updatedValue !== undefined ? !!updatedValue : savedValue}
			onChange={() => appDispatch({
				type: 'UPDATE_USER_FIELD',
				field: setting,
				value: !appState.user?.row?.[setting] })} />
		<label htmlFor={setting}><FontAwesomeIcon icon={field.icon} />{title}<span className='tip'> - {field.description}</span></label>
	</p>
}

const WRITE_FIELDS: {
	[field: string]: {
		icon: IconProp
		title?: string
		description?: string
		adminOnly?: true
	}
} = {
	title: { icon: 'quote-left', description: 'A short header for each entry' },
	summary: { icon: 'list-alt', description: 'a bullet-point overview of the day' },
	loc: { icon: 'map-marker-alt', title: 'Location', description: 'where were you?' },
	rating: { icon: 'star', description: 'how enjoyable was the event?' },
	energy: { icon: 'bolt', description: 'how active were you?' },
	importance: { icon: 'award', description: 'how memorable was this event?' },
	price: { icon: 'money-bill', description: 'how much did you spend during the event?' },
	voice: { icon: 'microphone-lines', description: 'automatically transcribe audio logs', adminOnly: true },
	date: { icon: 'calendar-day', description: 'change the entry\'s stored day' },
	type: { icon: 'calendar-week', title: 'Spans', description: 'mark (and easily analyse) periods of time, not just one day.' }
}

const UserSettings = (): JSX.Element => {
	const { appDispatch, isUserType } = useContext(AppContext)
	return <>
		<h2>Account control</h2>

		<div className='thumbnail-list settings-thumbnail-list'>

			<Prepare />

			<div className='thumbnail thumbnail-static'>
				<h3><FontAwesomeIcon icon='recycle'/>Clear cache</h3>
				If you wish, you can <span onClick={() => clearAllCaches(appDispatch)} className='clickable'>
					clear data
				</span> the site has stored in your browser.
				<div className='tip'>
					This should be done periodically.
					You will need an Internet connection to access existing entries.
					Unsaved entries will never be cleared.
				</div>
			</div>

			<div className='thumbnail thumbnail-static'>
				<h3><FontAwesomeIcon icon='list-check'/>Extra fields</h3>
				<div className='tip'>If you wish, you can store more than just text in an entry.</div>
				{ Object.keys(WRITE_FIELDS).map((field) => <UserFieldToggle key={field} field={field} />) }
			</div>

			<UserPropertyToggle field='is_power_user' title='Power user'
				description='Power users can access extra, potentially overwhelming, statistics around the site.' />

			<div className='thumbnail thumbnail-static'>
				<h3><FontAwesomeIcon icon='key'/>Password reset</h3>
				<div className='tip'>You can reset your password using the link
						on the Auth0 Login screen when
				<FontAwesomeIcon icon='sign-out-alt' className='logout-icon'/>
						logged out.
				</div>
			</div>

			{ isUserType('power') && <Sync /> }
		</div>
	</>
}

export default UserSettings
