import { Dispatch, LoadDetailsAction, LoadIdsAction, PrimaryAction } from '../helpers/action/Action.d'
import { BasicContextState } from '../helpers/context.d'
import { Context } from 'react'

export const infiniteScrollComparisons = ['above', 'below', 'before', 'after', 'exactly', 'contains'] as const
export type InfiniteScrollParams = {
  field: string
  value?: string
  comparison?: typeof infiniteScrollComparisons[number]
  /** Currently only used with field == 'search' */
  caseSensitive?: boolean
  offset?: number
}
export type InfiniteScrollParamsRaw = InfiniteScrollParams & {
  comparison?: string
  caseSensitive?: string | undefined
  offset?: string
}
export type InfiniteScrollHeaderProps = (InfiniteScrollParams | InfiniteScrollParamsRaw) & {
    /** When field == 'person', this text is prepended to their name */
    personLinkText: string
}

/** This must also have a state field matching InfiniteScrollContextState */
export type InfiniteScrollContextProvided = {
  state: InfiniteScrollContextState
  dispatch: Dispatch<PrimaryAction>
}

export type InfiniteScrollContextState = BasicContextState & {
  idsByQuery?: Record<string, number[]>
}

export type InfiniteScrollProps = {
	/** Note: context state must have a 'failed' object at top level */
	context: Context<InfiniteScrollContextProvided>
	/** Takes an item ID and returns whether enough data is present to render it */
	itemCanBeRendered: ItemCanBeRendered
	/** Turn an item into a JSX element to be displayed */
	renderItem: RenderItem
	/** If a query has strictly one response, it may be output differently */
	renderOneItemOnly?: RenderItem
	/** Action to dispatch to request a specific set of items by ID */
	requestDetailsAction: LoadDetailsAction['type']
	/** Either an action to query for IDs, or a function to generate those IDs */
	produceIds: ProduceIds
	/** This can be any value up to the number returned per API call */
	itemsPerPage?: number,
	/** If a query fails and there's no data to output, this error will be shown */
	errorMessage?: string
	/** No scrolling allowed beyond this number of items displayed */
	maxItems?: number
	/** These items will be omitted without triggering loading spinners */
	skippedItems?: number[]
	optionalTitle?: JSX.Element
	key?: string
}

export type ItemCanBeRendered = (id: number) => boolean
export type RenderItem = (id: number, index: number) => JSX.Element | false | undefined
export type ProduceIds = LoadIdsAction
