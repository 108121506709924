import { Dispatch, PrimaryAction } from '../../helpers/action/Action.d'
import { formatDate } from '../../helpers/format'
import { AppContextState, ReceiveDataVersion } from '../AppContext.d'

/** Generate a function to fire an API request to grab the current data 'version' */
export const receiveDataVersion = (appState: AppContextState, appDispatch: Dispatch<PrimaryAction>): ReceiveDataVersion =>
	(action) => {
		if (action.apiResponse?.version &&
		action.apiResponse?.version !== appState.dataVersion?.latest) {
			appDispatch({
				type: 'RECEIVE_DATA_VERSION',
				version: action.apiResponse.version,
				redispatch: appDispatch
			})
		}
	}

/** Force-wipe all caches when requested */
export const clearAllCaches = (appDispatch: Dispatch<PrimaryAction>): void =>
	appDispatch({ type: 'RECEIVE_DATA_VERSION', version: -1, redispatch: appDispatch, resetChanged: true })

/** When the date changes, trigger a rerender of everything so the jump-to-today buttons are updated. */
export const regularlyCheckToday = (appState: AppContextState, appDispatch: Dispatch): () => void => () => {
	const now = new Date()
	// Start from the first second of the next minute, in case someone's watching at midnight.
	const nextMinute = new Date(`${formatDate()} ${now.getHours()}:${now.getMinutes()}`).getTime() + 60 * 1000 + 250
	setTimeout(() => {
		if (appState.today !== formatDate()) {
			appDispatch({ type: 'RESET_TODAY' })
		}
		setInterval(() => {
			if (appState.today !== formatDate()) {
				appDispatch({ type: 'RESET_TODAY' })
			}
		}, 60 * 1000)
	}, nextMinute - now.getTime())
}
