import React, { createContext, useContext, useReducer } from 'react'
import { SettingsReducer, SettingsDispatcher } from '../reducers/SettingsReducer'
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom'
import { generateDispatcher } from '../helpers/dispatch'
import { defaultContextConfig, initialiseState } from '../helpers/context'
import { AppContext } from '../contexts/AppContext'
import { isCurrentPage } from '../helpers/path'
import { redirect } from '../helpers/path'
import PropTypes, { InferProps } from 'prop-types'
import { ContextConfig } from '../helpers/context.d'
import { triggerInstanceChecks } from '../Settings/controller'
import { SettingsContextProvided, SettingsContextState } from './SettingsContext.d'

const defaultContextProvided: SettingsContextProvided = {
	state: {
		instances: {},
		prepare: {},
		sync: {}
	},
	dispatch: () => { throw Error('Dummy dispatch invoked') }
}

export const SettingsContext = createContext<SettingsContextProvided>(defaultContextProvided)

export const contextConfig: ContextConfig<SettingsContextState> = {
	...defaultContextConfig,
	localStorageLocation: 'settings',
	defaultState: {
		instances: {},
		prepare: {}
	},
	retainedOnCacheClear: {
		instances: {},
		prepare: {}
	},
	freshState: {
		instances: {},
		prepare: {}
	}
}

const SettingsContextProvider = (props: InferProps<typeof settingsContextProviderPropTypes>): JSX.Element => {
	const appContext = useContext(AppContext)

	// Initialise.
	const [state, reducerDispatch] = useReducer<typeof SettingsReducer, SettingsContextState>(
		SettingsReducer,
		defaultContextProvided.state,
		initialiseState(contextConfig, appContext.appState))

	const location = useLocation()
	const dispatch = generateDispatcher(reducerDispatch, SettingsDispatcher, appContext)

	if (appContext.hasLoginScope('auth0')) {
		triggerInstanceChecks(state, dispatch,
			isCurrentPage('/admin', location.pathname) && appContext.hasLoginScope('admin'))
	}

	const history = useHistory()
	// eslint-disable-next-line dot-notation
	const feature = useRouteMatch({ path: '/redirect/:feature' })?.params?.['feature'] as string | undefined
	redirect(feature, dispatch, appContext?.appDispatch, location, history)

	// Save updates in local storage.
	// TODO put only user settings in the dependents array, to avoid spam
	//  when updating admin stuff.
	// useEffect(() => {
	// 	localStorage.setItem('settings', JSON.stringify(settings))
	// }, [settings.userData])

	return (
		<SettingsContext.Provider value={{ state, dispatch }}>
			{props.children}
		</SettingsContext.Provider>
	)
}

const settingsContextProviderPropTypes = {
	children: PropTypes.node.isRequired
}
SettingsContextProvider.propTypes = settingsContextProviderPropTypes

export default SettingsContextProvider
