import React, { useContext, useState } from 'react'
import { PersonContext, contextConfig } from '../contexts/PersonContext'
import InfiniteScroll, { infiniteScrollConfig } from '../common/InfiniteScroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { AppContext } from '../contexts/AppContext'
import Loading from '../common/Loading'
import { MIN_SEARCH_STRING_LENGTH } from './SearchPage'
import { RenderItem } from '../common/InfiniteScroll.d'
library.add(faUsersViewfinder, faCircleXmark)

const MAX_SHOWN_RESULTS = 5
const MAX_SELECTED_RESULTS = 5

const SearchResult = (props: { id: number,
    index: number,
    included: number[] | undefined,
    setSearch: (search: string) => void,
    hovered: number | undefined,
    setHovered: (id: number | undefined) => void
    hoveredSelected: boolean,
    setHoveredSelected: (id: boolean) => void
}): JSX.Element | false => {
	const { state, dispatch } = useContext(PersonContext)
	if (props.included?.includes(props.id)) {
		return false
	}
	const name = state.people?.[props.id]?.name
	if (!state.people || !name) {
		return <Loading />
	}
	const select = () => {
		props.setHoveredSelected(false)
		props.setHovered(undefined)
		props.setSearch('')
		dispatch({ type: 'SET_COMPARED_PEOPLE', people: (props.included || []).concat([props.id]) })
	}
	if (props.index === props.hovered && props.hoveredSelected) {
		setTimeout(select)
	}
	const country = state.people[props.id]?.country && <> (<span className='tip'>{state.people[props.id].country}</span>)</>
	return <div className={`clickable${props.index === props.hovered ? ' hovered' : ''}`}
		onMouseDown={select}
		onTouchStart={select}
		onMouseOver={() => props.setHovered(props.index)}
		onMouseOut={() => props.setHovered(undefined)}
	>{name}{country}</div>
}

const SelectedResult = (props: { id: number }): JSX.Element => {
	const { state, dispatch } = useContext(PersonContext)
	const name = state.people?.[props.id]?.name || <span className='tip'>&lt;Unknown&gt;</span> // TODO test this
	const selected = state.comparedPeople || []
	const deselect = () => dispatch({ type: 'SET_COMPARED_PEOPLE', people: selected.filter(id => id !== props.id) })
	return <>{name} <FontAwesomeIcon className='clickable' icon='circle-xmark' onClick={deselect}/> </>
}

const SelectedResults = (props: { selected: number[] }): JSX.Element => {
	if (!props.selected?.length) {
		return <></>
	}
	return <div><strong>Selected</strong>: {props.selected.map(id => <SelectedResult id={id} key={id} />)}</div>
}

const searchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>,
	hovered: number | undefined, setHovered: (i: number | undefined) => void,
	setHoveredSelected: (selected: boolean) => void,
	setSearch: (search: string) => void): void => {
	if (e.key === 'ArrowUp') {
		setHovered((hovered === undefined || hovered == 0) ? MAX_SHOWN_RESULTS - 1 : hovered - 1)
	} else if (e.key === 'ArrowDown') {
		setHovered((hovered === undefined || hovered == MAX_SHOWN_RESULTS - 1) ? 0 : hovered + 1)
	} else if (e.key === 'Enter' && hovered !== undefined) {
		setHoveredSelected(true)
	} else if (e.key === 'Escape') {
		setSearch('')
	}
}

const PickerSearch = (props: { selected: number[], preselected?: number[] }): JSX.Element => {
	const { setShortcutsActive } = useContext(AppContext)
	const { state } = useContext(PersonContext)
	const [search, setSearch] = useState<string>('')
	const [shown, setShown] = useState(false)
	const [hovered, setHovered] = useState<number | undefined>()
	const [hoveredSelected, setHoveredSelected] = useState(false)

	const render: RenderItem = (id, index) => <SearchResult key={id} id={id} index={index}
		setSearch={setSearch}
		included={props.selected}
		hovered={hovered} setHovered={setHovered}
		hoveredSelected={hoveredSelected} setHoveredSelected={setHoveredSelected} />
	const searchConfig = infiniteScrollConfig(
		{ ...contextConfig, renderThumbnail: render, renderDetails: render },
		state,
		{ field: 'name', comparison: 'contains', value: search })
	searchConfig.skippedItems = props.selected.concat(props.preselected || [])
	searchConfig.maxItems = MAX_SHOWN_RESULTS

	return <><label htmlFor='picker-search-term'><FontAwesomeIcon icon='users-viewfinder'/> </label>
		<input id='picker-search-term' value={search} placeholder='Type a tag or a name...'
			onChange={(e) => setSearch(e.target.value)}
			onKeyDown={e => searchKeyPress(e, hovered, setHovered, setHoveredSelected, setSearch)}
			onFocus={() => { setShortcutsActive(false); setShown(true) } }
			onBlur={() => { setShortcutsActive(true); setShown(false) }} />
		{ shown && search.length >= MIN_SEARCH_STRING_LENGTH && <div className='floating-query-results'>
			<InfiniteScroll {...searchConfig} key={'picker'} />
		</div> }
	</>
}

const Picker = (props: { preselected?: number[] }): JSX.Element => {
	const { state } = useContext(PersonContext)

	const selected = (state.comparedPeople || [])

	return <>
		<SelectedResults selected={selected} />
		{ selected.length < MAX_SELECTED_RESULTS && <PickerSearch selected={selected} preselected={props.preselected} />}
	</>
}

export default Picker
