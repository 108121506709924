import { ApiResponseAction, Dispatch } from '../helpers/action/Action.d'
import { SettingsAction, SettingsInstanceId } from '../helpers/action/SettingsAction.d'
import { BasicContextState } from '../helpers/context.d'

export type SettingsContextProvided = {
	dispatch: Dispatch<SettingsAction>
	state: SettingsContextState
}

export const INSTANCE_CONTROL_STOPPED = 'stopped'
export const INSTANCE_CONTROL_STARTED = 'started'
export const INSTANCE_CONTROL_STOPPING = 'stopping'
export const INSTANCE_CONTROL_STARTING = 'starting'
export const STATUS_ICON_SUCCESS = 'success'
export const BUTTON_REFRESH = 'refresh'
export const EXTERNAL_API_LINK = 'link'
export const EXTERNAL_API_UNLINK = 'unlink'
export const STATUS_ICON_LOADING = 'loading'
export const STATUS_ICON_FAILED = 'failed'
export const STATUS_ICON_WORKING = 'working'
export const STATUS_PREPARE_PENDING = 'pending'
export type InstanceStatusCode = typeof BUTTON_REFRESH
     | typeof EXTERNAL_API_LINK
     | typeof EXTERNAL_API_UNLINK
     | typeof STATUS_ICON_SUCCESS
     | typeof INSTANCE_CONTROL_STOPPED
     | typeof INSTANCE_CONTROL_STARTED
     | typeof INSTANCE_CONTROL_STOPPING
     | typeof INSTANCE_CONTROL_STARTING
     | typeof STATUS_ICON_LOADING
     | typeof STATUS_ICON_WORKING
     | typeof STATUS_ICON_FAILED
     | typeof STATUS_PREPARE_PENDING

export type SettingsContextState = BasicContextState & {
	instances: {
		[I in SettingsInstanceId]?: InstanceStatusCode
	},
	/** Governs whether or not followup instance status checks will be sent */
	windowFocused?: boolean
	prepare: PrepareStatus
	sync?: {
		[api: string]: {
			authenticating?: boolean
			authUrl?: string
			loading?: boolean
			text?: string
		}
	}
}

export type PrepareLog = {
	createdAt?: number
	duration?: number
	estimatedCompletionTime: number
	events?: string[]
	isComplete: boolean
	failed: boolean
	latestEvent?: number
	region: string
	logGroupName: string
	logStreamName: string
}

type PrepareStatus = {
	currentLog?: PrepareLog
	latestCompleteLog?: PrepareLog
	latestStoppedTask?: {
		exitCode?: number
		failed?: boolean
		statusReason?: string
		stoppedAt?: number
		stoppedReason?: string
		taskArn?: string
	}
	latestSuccessfulLog?: PrepareLog
	runningTasks?: []
}

export type CheckPrepareStatusApiResponse = ApiResponseAction & {
	apiResponse: {
		data: PrepareStatus
	}
}

export type InstanceStatus = {
	id: string
	isRunning: boolean
	isStopped: boolean
	isStarting: boolean
	isStopping: boolean
}
export type CheckInstanceStatusApiResponse = ApiResponseAction & {
	type: 'CHECK_INSTANCE_STATE'
	apiResponse: {
		data: InstanceStatus | Record<string, InstanceStatus>
	}
	sourceAction: SettingsAction
}
