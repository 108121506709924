import React from 'react'
import { matchPath } from 'react-router'
import { History, Location } from 'history'
import { Dispatch, PrimaryAction } from './action/Action.d'
import { AppContextState } from '../contexts/AppContext.d'
import { isUsingField } from '../contexts/app/auth'
import { PageLink, CatchShortcuts, GenerateShortcutsListener } from './path.d'

/** Context for navigation to various primary pages */
export const PAGE_LINKS: Record<string, PageLink> = {
	'/explore': { target: '/explore', shortcut: 'x', alts: ['/person', '/people', '/entry', '/entries'], toggle: true },
	// '/entries': { target: '/entries', shortcut: 'e', then: { target: '/entry', strict: true } },
	'/write': { target: '/write', shortcut: 'w', then: '/today' },
	'/admin': { target: '/admin', shortcut: 'a', scope: 'admin' },
	'/spans': { target: '/spans', shortcut: 'n', field: 'type' },
	'/search': { target: '/search', shortcut: 'r' },
	'/settings': { target: '/settings', shortcut: 's' }
}

/** A reverse index back from PAGE_LINKS' alts/then (verified by unit tests) */
export const SECONDARY_PATHS = {
	'/person': '/explore',
	'/people': '/explore',
	'/entry': '/explore',
	'/entries': '/explore',
	'/today': '/write'
}

/** Map keys to pages */
export const KEYBOARD_SHORTCUTS: Record<string, string> = {
	x: '/explore',
	// e: '/entries',
	w: '/write',
	n: '/spans',
	a: '/admin',
	r: '/search',
	s: '/settings'
}

export const getTargetPath = (target: string | undefined, path: string, appState?: AppContextState): string | undefined => {
	const targetConfig = target && target in PAGE_LINKS ? PAGE_LINKS[target as keyof typeof PAGE_LINKS] : undefined
	if (!target || (targetConfig?.field && (!appState || !isUsingField(targetConfig.field, appState)))) {
		return undefined
	}
	if (targetConfig?.then && (isCurrentPage(target, path) || isCurrentPage(targetConfig.then, path))) {
		return targetConfig.then
	}
	const cachedAltPath = appState?.paths?.[target]
	if (targetConfig && targetConfig.alts && cachedAltPath && path !== cachedAltPath) {
		return cachedAltPath
	}
	return target
}

export const pageLink = (target: string | undefined): PageLink | undefined => target ? PAGE_LINKS[target] : undefined

export const linkWithShortcut = (text: string | undefined, target: string | undefined): string | JSX.Element | undefined => {
	const shortcut = pageLink(target)?.shortcut
	const replaced = shortcut && text ? text.match(RegExp(shortcut, 'i'))?.[0] : undefined
	if (!text || !replaced) {
		return text
	}
	return <>
		{text.substring(0, text.indexOf(replaced[0]))}
		<span className='page-link-shortcut'>{replaced}</span>
		{text.substring(text.indexOf(replaced[0]) + 1)}
	</>
}

export const getShortcut = (key: string): PageLink | undefined =>
	key in KEYBOARD_SHORTCUTS ? PAGE_LINKS[KEYBOARD_SHORTCUTS[key]] : undefined

export const isCurrentPage = (query: string, currentPath: string, allowSecondary = false): boolean =>
	query?.includes(':')
		? !!matchPath(currentPath, query)
		: (allowSecondary
			? getPageFromPath(query) === getPageFromPath(currentPath)
			: getPageSectionOfPath(query) === getPageSectionOfPath(currentPath))

export const getQueryParam = (location: Location, paramName: string): string | null =>
	new URLSearchParams(location.search).get(paramName)

export const isPageUrl = (path: string): boolean => {
	if (typeof path !== 'string') { return false }
	return path !== '/' && (path.match(/[/]/g) || []).length === 1
}

const getPageSectionOfPath = (path: string | null | undefined): string => {
	if (typeof path !== 'string') { return '/' }
	const pageSection = path.substring(0, path.search(/.\//) + 1)
	return pageSection === '' ? path : pageSection
}

export const getPageFromPath = (path: string): string => {
	const page = getPageSectionOfPath(path)
	return page in SECONDARY_PATHS
		? SECONDARY_PATHS[page]
		: page
}

const redirectUrl = (feature: string, code: string) => {
	switch (feature) {
	case 'fitbit':
		if (!code) { return '/settings' }
		return '/settings?redirected=fitbit'
	default:
		return '/'
	}
}

/** Perform any redirects necessary, and return whether any were needed. */
export const redirect = (feature: string | undefined,
	dispatch: Dispatch<PrimaryAction>, appDispatch: Dispatch<PrimaryAction>,
	location: Location, history: History): boolean => {
	if (!feature) { return false }
	const code = new URLSearchParams(location.search)?.get('code')
	if (feature === 'fitbit') {
		if (code) {
			dispatch({ type: 'SYNC_FITBIT_AUTH_CALLBACK', api: 'fitbit', code, appDispatch })
		}
	} else {
		console.error(`Unknown redirect '${feature}'`)
		return false
	}
	if (!code) {
		console.error('No redirect code provided')
		return false
	}
	history.push(redirectUrl(feature, code))
	return true
}

export const generateShortcutsListener: GenerateShortcutsListener = (shortcutsActive, history, path, appState, hasLoginScope) =>
	(keydown) => {
		if (!shortcutsActive) { return }
		if (keydown.ctrlKey || keydown.metaKey) { return }
		const shortcut = getShortcut(keydown.key)
		// skip if: key isn't a shortcut
		if (!shortcut
			// or shortcut requires unmet scope
			|| (shortcut.scope && !hasLoginScope(shortcut.scope))) {
			return
		}
		const target = getTargetPath(shortcut.target, path, appState)
		if (path === target || !target) { return }
		history.push(target)
	}

/** Generate a function to respond to shortcuts and change pages accordingly */
export const catchShortcuts: CatchShortcuts = (shortcutsActive, history, path, appState, hasLoginScope) => () => {
	const keydown = generateShortcutsListener(shortcutsActive, history, path, appState, hasLoginScope)
	window.addEventListener('keydown', keydown)
	return () => {
		window.removeEventListener('keydown', keydown)
	}
}

/** Note down in the app cache when we change a page */
export const cacheCurrentPage = (path: string, appState: AppContextState,
	appDispatch: Dispatch<PrimaryAction>): void => {
	const page: string = getPageFromPath(path)
	if (path !== '/' && appState.paths?.[page] !== path && path !== page) {
		appDispatch({ type: 'CHANGE_URL', path })
	}
}
