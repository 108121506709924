import config from '../common/auth_config.json'
import { FORCE_CACHE_CLEAR_VERSION, treatLocalAction } from '../helpers/context'
import { getPageFromPath } from '../helpers/path'
import { Reducer } from 'react'
import { AppContextState, UserRow } from '../contexts/AppContext.d'
import { AnyAction, ApiResponseAction, PrimaryAction, ReceiveDataVersionAction } from '../helpers/action/Action.d'
import { SuperDispatch } from '../helpers/dispatch.d'
import { AppLevelAction } from '../helpers/action/AppLevelAction.d'
import { NOTIFICATIONS_SHOWN_FOR } from '../NavBar/NavNotificationBar'
import { toggleExploreHelpSection } from '../Explore/ExploreHelp'
import { formatDate } from '../helpers/format'

export const AppReducer: Reducer<AppContextState, AnyAction> = (state, action) =>
	'apiResponse' in action
		? AppReducerApiResponse(state, action)
		: AppReducerLocal(state, action)

export const AppReducerLocal: Reducer<AppContextState, PrimaryAction> = (state, action) => {
	switch (action.type) {
	case 'CHANGE_URL':
		state.paths = state.paths || {}
		state.paths[ getPageFromPath(action.path) ] = action.path
		return { ...state }

	case 'STORE_AUTH':
		return {
			...state,
			user: {
				...action.userObj,
				isAuthenticated: true,
				scope: action.scope.split(' '),
				status: 'Retrieving user data...'
			}
		}

	case 'UPDATE_LOGIN_STATUS':
		return {
			...state,
			user: {
				...state.user,
				status: action.status
			}
		}

	case 'UPDATE_USER_FIELD':
		return {
			...state,
			user: {
				...state.user,
				updated: {
					...state.user?.updated,
					[action.field]: action.value
				}
			}
		}

	case 'SKIP_AUTH_TO_USER':
		localStorage.setItem('disableAuth', String(!state.disableAuth))
		return {
			...state,
			user: state.disableAuth ? {} : {
				...config.dummyAuthState,
				scope: action.user
					? config.dummyAuthState.scope
					: [...config.dummyAuthState.scope, 'admin'],
				status: 'Logging in...',
				userOverride: action.user && 'public|' + action.user
			},
			disableAuth: !state.disableAuth
		}

	case 'FLAG_LOGIN_ERROR':
		return {
			...state,
			user: {
				...state.user,
				loginProblemDetected: action.error
			}
		}

	case 'LOG_OUT':
		// Replace state with ephemeral flags saying we're logging out.
		localStorage.clear()
		if (state.user?.IS_SPECIAL_USER) {
			return {}
		}
		return {
			loggingOut: true,
			user: {
				status: 'Logging out...'
			}
		}

	case 'EXPLORE_SELECT_TAB':
		return {
			...state,
			explore: {
				...state.explore,
				tab: action.tab
			}
		}

	case 'SEARCH_SELECT_DOMAIN':
		return {
			...state,
			search: {
				...state.search,
				domain: action.domain
			}
		}

	case 'SEARCH_SET_TERM':
		return {
			...state,
			search: {
				...state.search,
				term: action.term
			}
		}

	case 'SEARCH_SET_CASE_SENSITIVE':
		return {
			...state,
			search: {
				...state.search,
				caseSensitive: action.sensitive
			}
		}

	case 'SET_NOTIFICATION_TEXT':
		return {
			...state,
			notification: {
				message: action.message,
				icon: action.icon,
				flipped: action.flipped
			}
		}

	case 'CLEAR_NOTIFICATION_TEXT':
		return { ...state, notification: {} }

	case 'TOGGLE_HELP':
		return {
			...state,
			explore: {
				...state.explore,
				help: toggleExploreHelpSection((state.explore?.help || []) as string[], action.section as string)
			}
		}

	case 'RESET_TODAY':
		return { ...state, today: formatDate() }

	// When we've received a data 'version' update, note it in state
	case 'RECEIVE_DATA_VERSION':
		return treatReceiveDataVersion(state, action)

	case 'TOGGLE_OFFLINE_MODE':
		return { ...state, offlineMode: action.offline }

	case 'TOGGLE_NAV_BAR_MENU':
		return { ...state, menuShown: state.menuShown === action.menu ? undefined : action.menu }
	case 'DISABLE_NAV_BAR_MENUS':
		return { ...state, menuShown: undefined }

	default:
		return treatLocalAction(state, action)
	}
}

const treatReceiveDataVersion: Reducer<AppContextState, ReceiveDataVersionAction> = (state, action) => {
	const versionChanged = !action.resetChanged
		&& (state.dataVersion?.latest !== FORCE_CACHE_CLEAR_VERSION && action.version !== FORCE_CACHE_CLEAR_VERSION)
		&& (!!state.dataVersion?.latest && action.version !== state.dataVersion.latest)
	// Show the notification bar for a limited period of time.
	if (versionChanged) {
		setTimeout(
			() => action.redispatch?.({ type: 'CLEAR_NOTIFICATION_TEXT' }), NOTIFICATIONS_SHOWN_FOR * 1000)
	}
	return {
		...state,
		dataVersion: {
			latest: action.version,
			changed: versionChanged
		}
	}
}

export const AppReducerApiResponse: Reducer<AppContextState, ApiResponseAction> = (state, action) => {
	if (action.type === 'API_REQUEST_FAILED' || action.type === 'API_REQUEST_SUCCEEDED') {
		return treatLocalAction(state, action)
	}

	switch (action.sourceAction.type) {

	case 'LOAD_USER':
		if (typeof action.apiResponse?.data !== 'object')
			throw new Error('Unexpected user data type')
		return {
			...state,
			user: {
				...state.user,
				row: action.apiResponse.data as UserRow,
				status: 'ready' // Mark that the app can be loaded now.
			}
		}

	case 'SAVE_USER_FIELD':
		delete state.user?.updated?.[action.sourceAction.field ]
		return {
			...state,
			user: {
				...state.user,
				row: {
					...state.user?.row,
					[action.sourceAction.field]: action.sourceAction.value
				}
			}
		}

	case 'REQUEST_METADATA':
		if (typeof action.apiResponse?.data !== 'object' || Array.isArray(action.apiResponse.data) ||
			typeof action.apiResponse?.data.site !== 'object' ||
			typeof action.apiResponse?.data.site?.['prepare'] !== 'object' || // eslint-disable-line dot-notation
			typeof action.apiResponse?.data.site?.['prepare'].completed !== 'number') // eslint-disable-line dot-notation
			throw new Error('Malformed API response')
		// eslint-disable-next-line dot-notation, @typescript-eslint/no-unsafe-assignment
		action.receiveDataVersion?.({ ...action, apiResponse: { version: action.apiResponse.data.site['prepare'].completed } })
		return {
			...state,
			metadata: action.apiResponse.data
		}

	default:
		return treatLocalAction(state, action)
	}
}

// This function governs API-calling actions. They may later end up above.
export const AppDispatcher: SuperDispatch<AppLevelAction> = (action, dispatch, dispatchToApi) => {
	switch (action.type) {
	case 'REQUEST_METADATA':
		return dispatchToApi('general/metadata')

	case 'LOAD_USER':
		return dispatchToApi('general/user')

	case 'SAVE_USER_FIELD':
		return dispatchToApi(
			`general/user?key=${action.field}&value=${String(action.value === undefined ? '' : action.value)}`,
			{ method: 'PUT' })

	default:
		return dispatch(action)
	}
}
