import { AppContextState } from '../contexts/AppContext.d'
import { INSTANCE_CONTROL_STARTING, INSTANCE_CONTROL_STOPPING, STATUS_ICON_WORKING, STATUS_PREPARE_PENDING,
	InstanceStatusCode, SettingsContextState } from '../contexts/SettingsContext.d'
import { conditionalDelayedDispatch, conditionalDispatch } from '../helpers/action/action'
import { ApiResponseAction, Dispatch, PrimaryAction } from '../helpers/action/Action.d'
import { CheckInstanceAction, SettingsAction, SettingsInstanceId,
	SETTINGS_SYNC_APIS, SETTINGS_INSTANCE_IDS, SETTINGS_INSTANCE_IDS_AUTOCHECK } from '../helpers/action/SettingsAction.d'

// How long to wait between automatic status checks.
export const PREPARE_INTERVAL_SECONDS = 20
export const INSTANCE_INTERVAL_SECONDS = 5

export const checkInstanceAction = (instance: SettingsInstanceId): CheckInstanceAction => ({
	type: instance === 'prepare' ? 'CHECK_PREPARE_STATUS' : 'CHECK_INSTANCE_STATE',
	instanceId: instance
})

export const isInstanceStatusChanging = (
	instance: SettingsInstanceId | CheckInstanceAction | ApiResponseAction<CheckInstanceAction>,
	state: SettingsContextState): boolean => {
	const status = instanceStatus(instance, state)
	const fluxStatuses: InstanceStatusCode[] = [
		STATUS_ICON_WORKING, STATUS_PREPARE_PENDING,
		INSTANCE_CONTROL_STARTING, INSTANCE_CONTROL_STOPPING]
	return !!status && fluxStatuses.includes(status)
}

export const instanceStatus = (
	instance: SettingsInstanceId | CheckInstanceAction | ApiResponseAction<CheckInstanceAction>,
	state: SettingsContextState
): InstanceStatusCode | undefined =>
	state.instances?.[
		typeof instance === 'string'
			? instance
			: 'sourceAction' in instance
				? instance.sourceAction.instanceId
				: instance.instanceId ]

// Run on loading the Admin page.
export const triggerInstanceChecks = (state: SettingsContextState, dispatch: Dispatch<SettingsAction>, admin = true): void => {
	if (state.windowFocused === false) {
		return
	}
	for (const instanceId of SETTINGS_INSTANCE_IDS) {
		if (instanceId !== 'prepare' && !admin) {
			continue
		}
		if (isInstanceStatusChanging(instanceId, state) ||
			(!instanceStatus(instanceId, state) && SETTINGS_INSTANCE_IDS_AUTOCHECK.includes(instanceId))) {
			conditionalDispatch(checkInstanceAction(instanceId), state, dispatch, {
				statusResetDelay: instanceId === 'prepare' ? PREPARE_INTERVAL_SECONDS : INSTANCE_INTERVAL_SECONDS
			})
		}
	}
}

// Run at the AppContext level.
export const saveUserFields = (state: AppContextState, dispatch: Dispatch<PrimaryAction>): void => {
	if (!state.user?.row || !state.user.updated) {
		return
	}
	for (const field in state.user.updated) {
		saveUserField(field, state, dispatch)
	}
}

const saveUserField = (field: string, state: AppContextState, dispatch: Dispatch<PrimaryAction>): void => {
	if (state?.user?.updated?.[field] === undefined) { return }
	for (const api of SETTINGS_SYNC_APIS) {
		if (field.startsWith(api)) { return }
	}
	conditionalDispatch({ type: 'SAVE_USER_FIELD', field, value: state.user.updated[field] }, state, dispatch)
}

export const specialSyncActions = (
	appState: AppContextState,
	appDispatch: Dispatch<PrimaryAction>): void => {
	for (const api of ['fitbit', 'rescuetime'] as const) {
		for (const fieldBase of ['start_date', 'user_id', 'api_key']) {
			const field = `${api}_${fieldBase}`
			const baseValue = appState.user?.row?.[field]
			const modifiedValue = appState.user?.updated?.[field] as string
			if (modifiedValue !== undefined && baseValue !== modifiedValue) {
				const saveAction: PrimaryAction = { type: 'SAVE_USER_FIELD', field, value: modifiedValue }
				conditionalDelayedDispatch(saveAction, appState, appDispatch, 1)
			}
		}
	}
}
