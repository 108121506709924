
export const getApiEndpointUrl = () => {
	// Read an environment variable built into the scripts.
	if (process.env.REACT_APP_API_ENDPOINT !== undefined) {
		return process.env.REACT_APP_API_ENDPOINT
	}
	// If none found, assume we're on a dev machine.
	return 'http://localhost:3042'
}

export const getServerRoot = () => {
	const path = window.location.href
	return path.substr(0, path.indexOf('/', 8) + 1)
}

export const currentCommitLink = () =>
	`https://github.com/${
		process.env.REACT_APP_PROJECT_USERNAME || 'mm689'
	}/${
		process.env.REACT_APP_PROJECT_REPONAME || 'trove'
	}/tree/${
		process.env.REACT_APP_SHA1 || 'main'
	}/`

export const currentCommitLabel = () =>
	`${
		process.env.REACT_APP_SHA1?.substring(0, 8) || 'abcd1234'
	} on ${
		process.env.REACT_APP_BRANCH || '<unknown branch>'
	}`

export const buildLogLink = () => process.env.REACT_APP_BUILD_URL || 'https://app.circleci.com/pipelines/github/mm689/trove'
