import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { PersonContext, contextConfig } from '../contexts/PersonContext'
import InfiniteScroll, { infiniteScrollConfig, InfiniteScrollHeader } from '../common/InfiniteScroll'
import { InfiniteScrollParamsRaw } from '../common/InfiniteScroll.d'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowCircleRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { formatDate } from '../helpers/format'
library.add(faArrowCircleRight, faInfoCircle)

export const PersonThumbnailListWithField = (): JSX.Element => {
	const { state } = useContext(PersonContext)
	const params = useParams<InfiniteScrollParamsRaw>()
	const infiniteScrollProps = infiniteScrollConfig(contextConfig, state, params)

	return (
		<div className='thumbnail-list person-thumbnail-list'>
			<InfiniteScrollHeader {...params} personLinkText='Linked to' />
			<InfiniteScroll {...infiniteScrollProps} key={infiniteScrollProps.key} />
		</div>)
}

export const PersonThumbnailListFromPosition = (): JSX.Element => {
	const { state } = useContext(PersonContext)
	const { start } = useParams<{ start?: string }>()
	const infiniteScrollProps = infiniteScrollConfig({
		...contextConfig
	}, state, { field: 'id', comparison: 'above', value: start })
	return (
		<div className='thumbnail-list person-thumbnail-list'>
			<InfiniteScroll {...infiniteScrollProps} key={infiniteScrollProps.key} />
		</div>)
}

const TopPeopleThumbnailListTitle = (props: { type: 'name' | 'hashtag' | 'handle' | 'all', maxItems: number }) =>
	<h3>
		Top {props.maxItems} {props.type === 'all' ? 'overall' : `${props.type}s`}&nbsp;
		<Link to={props.type === 'all' ? '/people/by/count' : `/people/by/type/${props.type}`}>
			<FontAwesomeIcon icon='arrow-circle-right' />
		</Link>
	</h3>

export const TopPeopleThumbnailList = (props: { type: 'name' | 'hashtag' | 'handle' | 'all', maxItems: number }): JSX.Element => {
	const { state } = useContext(PersonContext)
	const infiniteScrollProps = infiniteScrollConfig(
		{ ...contextConfig, renderDetails: undefined },
		state,
		props.type === 'all' ? { field: 'count' } : { field: 'type', value: props.type })
	return (
		<div className='thumbnail-list person-thumbnail-list'>
			<InfiniteScroll
				maxItems={props.maxItems}
				optionalTitle={<TopPeopleThumbnailListTitle maxItems={props.maxItems} type={props.type} />}
				{...infiniteScrollProps}
				key={infiniteScrollProps.key} />
		</div>)
}

const RecentPeopleThumbnailListTitle = (props: { since: number, maxItems: number }) =>
	<h3>
    Top {props.maxItems} since {formatDate(props.since, 'long')}&nbsp;
		<Link to={'/people/by/date/after/' + formatDate(props.since)}>
			<FontAwesomeIcon icon='arrow-circle-right' />
		</Link>
	</h3>

export const RecentPeopleThumbnailList = (props: { date: number, maxItems: number }): JSX.Element => {
	const { state } = useContext(PersonContext)
	const infiniteScrollProps = infiniteScrollConfig(
		{ ...contextConfig, renderDetails: undefined },
		state,
		{ field: 'date', comparison: 'after', value: formatDate(props.date * 1000) })
	return (
		<div className='thumbnail-list person-thumbnail-list'>
			<InfiniteScroll
				maxItems={props.maxItems}
				optionalTitle={<RecentPeopleThumbnailListTitle since={props.date * 1000} maxItems={props.maxItems}/>}
				{...infiniteScrollProps}
				key={infiniteScrollProps.key} />
		</div>)
}

export default PersonThumbnailListFromPosition
