import React, { useContext, useState } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { AppContext } from '../contexts/AppContext'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTargetPath, isCurrentPage, linkWithShortcut } from '../helpers/path'
import { FlipProp, IconProp } from '@fortawesome/fontawesome-svg-core'

const NavBarButton = (props: NavBarButtonPropTypesType): JSX.Element => {
	const { appState, appDispatch } = useContext(AppContext)
	const onClick = props.onClick ? props.onClick : () => {
		if (props.togglesMenu) {
			appDispatch({ type: 'TOGGLE_NAV_BAR_MENU', menu: props.togglesMenu })
		} else {
			appDispatch({ type: 'DISABLE_NAV_BAR_MENUS' })
		}
	}
	const path = useLocation().pathname

	const [isImgLoaded, setIsImgLoaded] = useState<undefined | 'loaded' | 'failed'>(undefined)

	const targetPage = getTargetPath(props.target, path, appState)

	// If we're targeting a page we're already on, colour the icon to show that.
	const activeClassName = (path === '/' && props.default) ||
		(props.togglesMenu && appState.menuShown === props.togglesMenu) ||
		(props.target && isCurrentPage(props.target, path, props.isOnPageNavBar || false))
		? ' nav-bar-button-active' : ''
	let fullClassName = activeClassName
	// Buttons which toggle menus
	fullClassName += props.togglesMenu ? ' nav-bar-menu-toggle' + (props.src ? ' nav-bar-button-img' : '') : ''
	// Buttons with images to load which aren't yet loaded
	fullClassName += props.src && isImgLoaded === 'failed' ? ' nav-bar-button-img-failed' : ''
	// Button class(es) passed in from props
	fullClassName += props.className ? ' ' + props.className : ''
	const id = props.id ? props.id
		: props.togglesMenu ? props.togglesMenu + '-toggle-icon' : ''

	const overlay = props.overlay && <FontAwesomeIcon
		className={'nav-bar-button-overlay' + activeClassName}
		icon={props.overlay} spin={props.overlay === 'spinner'} />

	const img = props.src && <img
		src={props.src}
		alt={isImgLoaded === 'loaded' && props.alt || undefined}
		onError={() => setIsImgLoaded('failed')}
		onLoad={() => setIsImgLoaded('loaded')} />

	const text = linkWithShortcut(props.text, props.target)
	const icon = !props.src && !!props.icon
		? <>
			<FontAwesomeIcon icon={props.icon} flip={props.flip}/>
			{ overlay }
			{ props.text && <span className='nav-bar-button-text'>{text}</span> }
		</>
		: props.overlay && isImgLoaded === 'loaded'
			? <div className={'nav-bar-button nav-bar-button-img nav-bar-button-img-with-overlay' + fullClassName}>
				{ img }
				{ overlay }
				{ props.text && <span className='nav-bar-button-text'>{text}</span> }
			</div>
			: props.src
				? img
				: <></>

	// An icon to show while we wait for the requested image to load.
	const pendingIcon = props.src && isImgLoaded !== 'loaded' && <NavBarButton {...{ ...props, src: undefined }} />

	const button = <div id={id} onClick={onClick} className={`nav-bar-button${fullClassName}`}>{icon}</div>

	return targetPage
		? <Link to={targetPage}>{pendingIcon}{button}</Link>
		: <>{pendingIcon}{button}</>
}

/** NB either 'icon' or 'src' is required */
const navBarButtonPropTypes = {
	/** FontAwesome icon to display. Required if no 'src'; optional otherwise. */
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
	flip: PropTypes.string,
	/** Image to display. Takes precedence over 'icon' once image is loaded. */
	src: PropTypes.string,
	/** Alt text: required with 'src', ignored otherwise */
	alt: PropTypes.string,
	// NB if the button *could* be overlaid, pass overlay={false}
	overlay: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
	id: PropTypes.string,
	className: PropTypes.string,
	text: PropTypes.string,
	onClick: PropTypes.func,
	target: PropTypes.string,
	togglesMenu: PropTypes.string,
	isOnPageNavBar: PropTypes.bool,
	default: PropTypes.bool
}
NavBarButton.propTypes = navBarButtonPropTypes
type NavBarButtonPropTypesType = InferProps<typeof navBarButtonPropTypes> & {
	text?: string
	target?: string
	icon?: undefined | false | IconProp
	overlay?: undefined | false | IconProp
	flip?: undefined | false | FlipProp
}

export default NavBarButton
