import React, { Reducer } from 'react'
import { EntryContext, contextConfig } from '../contexts/EntryContext'
import { treatLocalAction, treatApiAction } from '../helpers/context'
import Retriever, { RetrieverConfig } from '../common/Retriever'
import { EntryId, Span } from '../Entry/Entry.d'
import { EntryContextState } from '../contexts/EntryContext.d'
import PropTypes, { InferProps } from 'prop-types'
import { AnyAction, ApiRequestFailedAction, ApiResponseAction, PrimaryAction } from '../helpers/action/Action.d'
import EntryContent from '../Entry/EntryContent'
import { EntryAction, SaveWriteEntryAction, SendDeleteWriteEntryAction } from '../helpers/action/EntryAction.d'
import { treatCacheWriteEntry, treatInterimWriteEntry, extraEntryApiResponseReducer, treatMarkDeleteWriteEntry,
	treatSaveWriteEntry, treatSendDeleteWriteEntry, treatSaveWriteEntryConflict } from '../Write/actions'
import { isEntryBlank, isInterimEntry } from '../Write/helpers'
import { EntrySpansContent } from '../Entry/EntrySpans'
import { SuperDispatch } from '../helpers/dispatch.d'
import { ExploreUserStats } from '../Explore/ExplorePage'
import { formatDate } from '../helpers/format'

export const EntryReducer: Reducer<EntryContextState, AnyAction> = (state, action) =>
	'apiResponse' in action
		? EntryReducerApiResponse(state, action)
		: EntryReducerLocal(state, action)

// This function governs actions which update local state.
export const EntryReducerLocal: Reducer<EntryContextState, PrimaryAction> = (state, action) => {
	switch (action.type) {
	case 'SELECT_ENTRY':
		return { ...state, selectedEntry: action.entryId }

	/** On clicking 'new entry', or on a new day, forget which entry we were writing. */
	case 'SELECT_WRITE_ENTRY':
		if (state.currentlyWriting && isInterimEntry(state.currentlyWriting) && isEntryBlank(state.write?.[state.currentlyWriting])) {
			delete state.write?.[state.currentlyWriting]
		}
		return { ...state, currentlyWriting: action.entryId || undefined }

	case 'CLEAR_ENTRY_REDIRECTS':
		return { ...state, redirectFrom: undefined, redirectTo: undefined }

	/** Set up a temporary entry to write to until we get an ID from the API. */
	case 'INTERIM_WRITE_ENTRY':
		return treatInterimWriteEntry(state, action)

	/** Save a keystroke or other attribute update in memory */
	case 'CACHE_WRITE_ENTRY':
		return treatCacheWriteEntry(state, action)

	case 'MARK_DELETE_WRITE_ENTRY':
		return treatMarkDeleteWriteEntry(state, action)

	case 'ENTRY_CONFLICT_DISCARD':
		delete state.write?.[action.id]
		delete state.failed?.[JSON.stringify({ type: 'SAVE_WRITE_ENTRY', entryId: action.id })]
		return { ...state }

	case 'ENTRY_CONFLICT_OVERWRITE':
		// the API won't detect conflicts without this field
		if (state.write && action.id in state.write) {
			state.write[action.id].saveStatus = 'saving'
			delete state.write[action.id].modified
		}
		delete state.failed?.[JSON.stringify({ type: 'SAVE_WRITE_ENTRY', entryId: action.id })]
		return { ...state }

	case 'ENTRY_SPAN_TOGGLE':
		if (state.spans?.[action.entryId] === undefined) {
			return state
		}
		return {
			...state,
			spans: {
				...state.spans,
				[action.entryId]: {
					...state.spans[action.entryId],
					collapsed: state.spans[action.entryId].collapsed !== undefined
						? !state.spans[action.entryId].collapsed
						: !!state.spans[action.entryId].spansNow
				}
			}
		}

	/** Initiate a save request */
	case 'TRIGGER_SAVE_WRITE_ENTRY':
		if (!state.write || !(action.entryId in state.write)) {
			console.error('Attempting to save an entry with no record in state')
			return state
		}
		// Blank entry: set the entry status to 'deleting', causing a SEND_DELETE_WRITE_ENTRY.
		if (isEntryBlank(state.write[action.entryId])) {
			return EntryReducer(state, { type: 'MARK_DELETE_WRITE_ENTRY', entryId: action.entryId })
		}
		// Otherwise, send the action to the API.
		void action.dispatchToApi(isInterimEntry(action.entryId) ? 'entry' : `entry/${action.entryId}`, {
			method: isInterimEntry(action.entryId) ? 'POST' : 'PUT',
			body: {
				...state.write?.[action.entryId],
				id: isInterimEntry(action.entryId) ? undefined : action.entryId,
				saveStatus: undefined,
				status: undefined
			}
		})
		return {
			...state,
			write: {
				...state.write,
				[action.entryId]: {
					...state.write[action.entryId],
					saveStatus: 'saving'
				}
			}
		}

	default:
		return treatLocalAction(state, action, contextConfig)
	}
}

const EntryReducerApiResponse: Reducer<EntryContextState, ApiResponseAction> = (state, action) => {

	switch (action.type) {

	/** Receive details of a successful save request */
	case 'SAVE_WRITE_ENTRY':
		return treatSaveWriteEntry(state, action as ApiResponseAction<SaveWriteEntryAction>)

	case 'API_REQUEST_FAILED':
		if (action.sourceAction.type === 'SAVE_WRITE_ENTRY' && action.status === 409) {
			return treatSaveWriteEntryConflict(state, action as ApiRequestFailedAction<SaveWriteEntryAction>)
		}
		return treatLocalAction(state, action, contextConfig)

	case 'SEND_DELETE_WRITE_ENTRY':
		return treatSendDeleteWriteEntry(state, action as ApiResponseAction<SendDeleteWriteEntryAction>)

	case 'RETRIEVE_SPANS':
		return {
			...state,
			actionStatus: {
				[JSON.stringify(action.sourceAction)]: 'complete'
			},
			spans: action.apiResponse as Record<EntryId, Span>
		}

	case 'QUERY_USER_STATS':
		return { ...state,
			actionStatus: {
				...state.actionStatus,
				[JSON.stringify(action.sourceAction)]: 'complete'
			},
			stats: {
				...state.stats,
				[JSON.stringify(action.sourceAction)]:
                    action.apiResponse?.data as EntryContextState['stats'][keyof EntryContextState['stats']]
			}
		}

	default:
		return treatLocalAction(
			extraEntryApiResponseReducer(state, action),
			action,
			contextConfig)
	}
}

// This function governs API-calling actions. They may later end up above.
export const EntryDispatcher: SuperDispatch<EntryAction> = (action, dispatch, dispatchToApi) => {

	switch (action.type) {
	case 'LOAD_ENTRIES_BY_ORDERS':
		return treatApiAction(action, dispatch, dispatchToApi, {
			...contextConfig, idField: 'order'
		})

	case 'SEND_DELETE_WRITE_ENTRY':
		return dispatchToApi('entry/' + action.entryId, { method: 'DELETE' })

	case 'RETRIEVE_SPANS':
		return dispatchToApi('entries/spans/', { method: 'GET' })

	case 'SAVE_WRITE_ENTRY':
		// Delegate to React's dispatch() so as to have access to state
		return dispatch({ ...action, type: 'TRIGGER_SAVE_WRITE_ENTRY', dispatchToApi })

	case 'QUERY_USER_STATS':
		return dispatchToApi(`general/stats?${action.from ? `from=${formatDate(action.from as string)}` : ''
		}${action.from && action.until ? '&' : ''}${action.until ? `until=${formatDate(action.until as string)}` : ''}`)

	default:
		return treatApiAction(action, dispatch, dispatchToApi, contextConfig)
	}
}

export const EntryRetrieverConfig: RetrieverConfig<EntryContextState, PrimaryAction> = (dataType) => {
	switch (dataType) {
	case EntryContent:
		return {
			isDataPresent: (entries, props) => !!(
				entries.entries?.[props.entryId as EntryId] ||
				entries.write?.[props.entryId as EntryId]),
			actionToDispatch: (_entries, props) => ({ type: 'LOAD_ENTRIES_BY_IDS', items: [props.entryId as EntryId] })
		}
	case EntrySpansContent:
		return {
			refresh: true,
			isDataPresent: (entries) => entries.spans !== undefined,
			actionToDispatch: () => ({ type: 'RETRIEVE_SPANS' })
		}
	case ExploreUserStats:
		return {
			refresh: true,
			isDataPresent: (state, props: { from?: string, until?: string }) =>
				!!state.stats && JSON.stringify({ type: 'QUERY_USER_STATS', from: props.from, until: props.until }) in state.stats,
			actionToDispatch: (_state, props) => ({
				type: 'QUERY_USER_STATS',
				from: props.from as string | undefined,
				until: props.until as string | undefined
			})
		}
	default:
		return undefined
	}
}

export const EntryRetriever = (props: InferProps<typeof entryRetrieverPropTypes>): JSX.Element =>
	<Retriever context={EntryContext} messageOn404="No such entry" config={EntryRetrieverConfig}>
		{props.children}
	</Retriever>

const entryRetrieverPropTypes = {
	children: PropTypes.element.isRequired
}
EntryRetriever.propTypes = entryRetrieverPropTypes
